<template>
  <div class="entrypackage">
    <navbarTools
      :leftArrow="true"
      :fixed="true"
      :rightText="rightText"
      :rightFn="rightFn"
    ></navbarTools>
    <div class="packageInfoBox">
      <img :src="OSS_URL + '/welfare/p_bg.png'" class="p_bg" />
      <div class="packageInfo">
        <div class="packageInfo_box_bg">
          <div class="packageInfo_bg">
            <div class="packageInfo_item_bg">
              <div
                class="packageInfo_item"
                v-for="(item, index) in equityList"
                :key="index"
              >
                <img :src="item.prizeImage" />
                <div class="title">{{ item.prizeName }}</div>
                <div>
                  <span class="mf">免费</span
                  ><span class="price">¥{{ item.prizePrice }}</span>
                </div>
              </div>
            </div>
            <van-button class="drawPackage" @click="drawFn"
              >免费领取</van-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from "vue";
import navbarTools from "@/components/navbarTools";
import { userGoodsEquityDtails } from "@/api/equity";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: {
    navbarTools,
  },
  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const state = reactive({
      count: 0,
      rightText: "使用规则",
      OSS_URL: process.env.VUE_APP_OSS_URL,
      searchObj: {
        equityId: $route.query.equityId,
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
      },
      equityList: [],
      ifClick: true,
    });
    const userGoodsEquityDtailsFn = async () => {
      let rs = await userGoodsEquityDtails(state.searchObj);
      if (rs.code === 0) {
        state.equityList = rs.data;
      }
    };

    const drawFn = () => {
      if (state.ifClick) {
        state.ifClick=false;
        setTimeout(()=>{
          state.ifClick=true;
        },2000)
        $router.replace({
          path: "/welfarecenter/drawpackage",
          query: {
            equityBatchId: $route.query.equityBatchId,
            limitStartTime: $route.query.limitStartTime,
            limitEndTime: $route.query.limitEndTime,
          },
        });
      }
    };
    const rightFn = () => {
      $router.push({
        path: "/welfarecenter/packagerules",
      });
    };
    userGoodsEquityDtailsFn();
    return {
      drawFn,
      rightFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.entrypackage {
  min-height: 100vh;
  height: auto;
  width: 100%;
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .packageInfoBox {
    position: relative;
    top: 46px;
    height: 100vh;
    .p_bg {
      width: 375px;
      height: 276px;
    }
    .packageInfo {
      width: 375px;
      height: auto;
      background: #fc7501;
      position: relative;
      top: -5px;
      padding-bottom: 10px;
      .packageInfo_box_bg {
        width: 343px;
        height: 490px;
        background: #ffd5b1;
        border-radius: 6px;
        position: relative;
        left: 16px;
        .packageInfo_bg {
          width: 323px;
          height: 470px;
          background: #fff;
          border-radius: 6px;
          position: relative;
          left: 10px;
          top: 10px;
          padding: 0px 13px;
          .packageInfo_item_bg {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            .packageInfo_item {
              margin-top: 10px;
              width: 137px;
              height: 180px;
              img {
                width: 137px;
                height: 137px;
              }
              .title {
                font-size: 14px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .mf {
                color: #fc7501;
                font-size: 15px;
                font-weight: 600;
              }
              .price {
                text-decoration: line-through;
                padding-left: 10px;
                font-size: 14px;
                color: #ccc;
              }
            }
          }
          .van-button {
            width: 295px;
            height: 44px;
            background: #fc7301;
            color: #fff;
            font-size: 16px;
            border-radius: 6px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
